
import {
    UploadOutlined,
    ArrowDownOutlined
} from "@ant-design/icons-vue"
import {
    setPageTab
} from "@/utils/page-tab-util"
export default {
    components: {
        UploadOutlined,
        ArrowDownOutlined
    },
    props: {
        visibleEdit: Boolean,
        ticket_data: Object
    },
    data() {
        return {
            data: [],
            fileList: [],
            form: {},
            // 内容列表
            replyLoading: false,
            current: {}
        }
    },
    watch: {
        "ticket_data.id": {
            handler(val) {
                this.fileList = [];
                this.form = {};
                this.onLoadContent(val)
            }
        }
    },
    computed: {
        visible: {
            get() {
                return this.visibleEdit
            },
            set(val) {
                this.$emit("update:visibleEdit", val)
            }
        },
        status() {
            const text = this.current && this.current.ticket_status
            return text !== undefined ? this.$t(`工单状态.${text}`) : ''
        },
        // 追踪号
        trackingnumberList() {
            if (!this.current.tracking_numbers) {
                return ''
            }

            return this.current.tracking_numbers.join(", ");
        },
        /**
         * 生成图像地址
         */
        createImageUrl() {
            let reg = /http/.test(this.$store.state.user.user.avatar);

            return !reg ? (process.env.VUE_APP_STS_FILE_URL + this.$store.state.user.user.avatar) : this.$store.state.user.user.avatar
        }
    },
    methods: {
        // 提交
        submit() {
            if (!this.form.reply_content && !this.form.files) {
                return
            }

            if (!this.form.files) {
                this.form.files = [];
            }

            if (!this.form.reply_content) {
                this.form.reply_content = "";
            }

            this.form.ticket_id = this.current.id;

            this.$http.post("/admin/ticket/reply", this.form).then(res => {
                if (res.data.code == 0) {
                    this.onLoadContent(this.current.id, true);
                    this.fileList = [];
                    this.form = {};
                    this.$message.success(res.data.msg);
                } else {
                    this.$message.error(res.data.msg);
                }
            })

        },
        // 上传附件
        handleUpload(resFile) {
            this.loading = true;
            const formData = new FormData();
            formData.append("file", resFile.file);

            this.$http
                .post("/main/upload_attachment", formData)
                .then((response) => {
                    if (response.status == 200 && response.data.code == 0) {
                        const res = response.data;

                        if (!this.form.files) this.form.files = [];

                        this.form.files.push({
                            file_path: res.data,
                            file_size: resFile.file.size,
                            file_name: resFile.file.name
                        })

                        this.$message.success(this.$t("操作成功"));
                        resFile.onSuccess(res.data, resFile.file);
                    }
                })
                .catch(() => {
                    resFile.onError();
                    this.$message.error(this.$t("操作失败"));
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // 加载详情
        onLoadContent(id, noNeedLoading) {
            !noNeedLoading && (this.replyLoading = true);
            Promise.all([
                this.$http.get(`/admin/ticket/get_detail/${id}`),
                this.$http.get(`/admin/ticket/get_replaies/${id}`)
            ]).then(res => {
                if (res[0].data.code == 0) {
                    this.current = Object.assign(res[0].data.data, {
                        id
                    });

                    setPageTab({
                        path: this.$route.path,
                        title: res[0].data.data.ticket_title
                    })

                    this.data = res[0].data.data.reply.reverse();
                }

                if (res[1].data.code == 0) {
                    this.data = res[1].data.data;
                }
            }).finally(() => {
                !noNeedLoading && (this.replyLoading = false);
            })
        },
        reload() {
            this.$emit("done");
        }
    }
}
